.dm-btn-container {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dm-btn {
  height: 70px;
  width: 200px;
  border-radius: 6px;
  background-color: rgb(83, 141, 78);
}

.dm-btn:hover {
  background-color: rgb(71, 122, 67);
}

.dm-btn:active {
  background-color: rgb(56, 97, 52);
}

.dm-btn-text {
  font-size: 1.8rem;
  font-weight: bold;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
