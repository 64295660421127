.tg-grid-container {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
}

.tg-grid {
    display: grid;
    grid-template: repeat(6, 1fr) / repeat(5, 1fr);
    gap: 5px;
    /* width: 250px; */
    /* height: 300px; */
    height: 100%;
    width: 100%;
    /* margin-bottom: 20px; */
    position: relative;
}

.tg-tile {
    border-style: solid;
    border-width: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    position: relative;
}

.tg-tile-letter {
    font-size: 3.3vh;
    font-weight: bold;
    user-select: none;
    padding: 0px;
    margin: 0px;
    /* background-color: pink; */
    /* font-family: 'Roboto', sans-serif; */
    /* font-weight: 900; */
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
    .tg-grid-container {
        /* display: flex; */
        /* flex-direction: column; */
        /* align-items: center; */
    }

    .tg-grid {
        /* width: 350px; */
        /* height: 420px; */
    }

    .tg-tile-letter {
        /* font-size: 32px; */
    }
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
    .tg-tile-letter {
        font-size: 30px;
    }
}


@media only screen and (min-width: 1200px) {
}
