.wm-header {
  margin-top: 10px;
}

.wm-text {
  line-height: 1.2;
  font-size: 0.9rem;
}

.wm-bold {
  font-weight: bold;
}

.wm-last-header-text {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.wm-examples-title {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.wm-example-word {
  display: flex;
  gap: 4px;
  margin-bottom: 10px;
}

.wm-tile {
  width: 40px;
  height: 40px;
  border-width: 2px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: rgb(158, 158, 158);
}

.wm-tile-letter {
  font-size: 30px;
  font-weight: bold;
}

.wm-footer {
  width: 100%;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
