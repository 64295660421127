@import url('https://fonts.googleapis.com/css2?family=Aladin&family=Roboto+Slab:wght@600;900&family=Titillium+Web&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@700&family=Bangers&family=Kameron:wght@700&family=Roboto:wght@900&family=Viga&display=swap');

body {
  color: white;
  background-color: black;
}

.firebase-emulator-warning {
  opacity: 0.0;
  pointer-events: none;
}
