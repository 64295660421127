.a-container {
  padding: 17px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  position: absolute;
  top: 10%;

  z-index: 100;
}

.a-container-invisible {
  opacity: 0;
  transition: all 0.5s;
}

.a-message {
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  padding: 0px;
  margin: 0px;
  color: black;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
