.w-page {
    padding: 10px;
    padding-bottom: 0px;
    /* display: flex; */
    /* justify-content: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: space-between;
    /* overflow: hidden; */
}

.w-watermark {
    height: 130vh;
    position: fixed;
    top: 50px;
    left: 20vw;
    z-index: -10;
    opacity: 0.1;
}

.w-title-container {
}

.w-title-wrapper {
    border-style: none;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: blue; */
}

.w-title {
    text-align: center;
    font-family: 'Roboto Slab', serif;
    /* font-family: 'Kameron', serif; */
    font-weight: bold;
    margin-top: 5px;
    font-size: 2rem;
    padding: 0px;
    margin-bottom: 0px;
    /* background-color: yellow; */
}

.w-title-img {
    height: 50px;
    object-fit: contain;
    margin-right: 15px;
}

.w-subtitle {
    text-align: left;
    width: 100%;
    font-size: 1.6rem;
    font-family: 'Roboto Slab', serif;
}

.w-entry-directions {
    width: 312;
}

.w-tile-big-container {
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    max-height: 350px;
    /* background-color: pink; */
}

.w-tile-container {
    display: inline-block;
    position: relative;
    height: 100%;
}

.w-grid-form {
    /* margin-bottom: 10px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.w-keyboard-container {
    /* width: 500px; */
    width: 100%;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}

@media only screen and (min-width: 400px) {
    .w-tile-big-container {
        max-height: 380px;
    }
}

@media only screen and (min-width: 576px) {
    .w-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .w-keyboard-container {
    }

    .w-title {
        font-size: 2.4rem;
    }

    .w-entry-directions {
        width: 500px;
    }

    .w-reset-btn {
        width: 150px;
    }
}


@media only screen and (min-width: 767px) {
    .w-page {
        height: 90vh;
    }
}


@media only screen and (min-width: 992px) {
    .w-keyboard-container {
        /* width: 500px; */
    }
}


@media only screen and (min-width: 1200px) {
    .w-title {
        margin-bottom: 10px;
    }

    .w-keyboard-container {
        /* display: flex;
        justify-content: center;
        width: 80%;
        gap: 50px; */
    }

    .w-grid-form {
        transition: all 1s ease-in-out;
    }

    .w-down-below-text {
        display: none;
    }
}

@media only screen and (min-width: 1500px) {
    .w-tile-big-container {
        max-height: 450px;
    }
}
